<template>
  <div :id="containerId">
    <lazy-hydrate never>
      <page-meta key="page-meta" />
    </lazy-hydrate>

    <!-- -------------------------------------------------------------------------------------- -->
    <!-- Header                                                                                 -->
    <!-- -------------------------------------------------------------------------------------- -->
    <elskling-header
      v-if="showNav && getDomain === 'elskling'"
      :content="routeMenu"
      :product="product"
    />

    <fragment
      v-else-if="showNav"
      key="header"
      name="header"
      pre-render
    />

    <!-- -------------------------------------------------------------------------------------- -->
    <!-- Form                                                                                   -->
    <!-- -------------------------------------------------------------------------------------- -->

    <section
      :class="['page', {
        'page-nav': showNav,
        'background-color--beige': beigeBackground
      }]"
    >
      <lazy-hydrate when-idle>
        <div
          v-if="showNav"
          key="bg"
          :class="['page__bg', {
            'page__siteinformation': hasSiteInformation,
          }]"
        />
      </lazy-hydrate>

      <routes
        key="route"
        :container-id="containerId"
        @loaded="loaded"
      />
    </section>

    <!-- -------------------------------------------------------------------------------------- -->
    <!-- Footer                                                                                 -->
    <!-- -------------------------------------------------------------------------------------- -->
    <elskling-footer
      v-if="showNav && getDomain === 'elskling'"
      :content="routeMenu"
      :product="product"
      :beige-background="beigeBackground"
    />

    <fragment
      v-else-if="showNav"
      key="footer"
      name="footer"
      pre-render
    />

    <event-listener />
  </div>
</template>

<script>
  import LazyHydrate from 'vue-lazy-hydration'
  import { mapGetters, mapMutations } from 'vuex'
  import Routes from './components/_routes/_Routes'
  import EventListener from './components/elements/misc/EventListener.vue'
  import ElsklingHeader from './components/_routes/elskling/Header'
  import ElsklingFooter from './components/_routes/elskling/Footer'
  import Fragment from './components/elements/Fragment'
  import PageMeta from './components/elements/PageMeta'
  import { getProp } from '@ocp-zmarta/zmarta-cl'
  import { addConnectelChat } from './widgets/chats/connectel'

  export default {
    name: 'App',
    components: {
      LazyHydrate,
      Routes,
      ElsklingHeader,
      ElsklingFooter,
      Fragment,
      PageMeta,
      EventListener
    },
    data: () => ({
      bgHeight: 0
    }),
    computed: {
      ...mapGetters('router', ['getRoute', 'getDomain']),
      ...mapGetters('features', ['getFeatures']),
      containerId () {
        return `${this.PROJECT_NAME}_${getProp(this.getRoute, 'component')}`
      },
      isFragment () {
        return getProp(this.getRoute, 'meta', 'fragment') === true
      },
      showNav () {
        return getProp(this.getRoute, 'meta', 'nav') !== false && !this.isFragment
      },
      routeMenu () {
        return getProp(this.getRoute, 'menu')
      },
      routePath () {
        return getProp(this.getRoute, 'path')
      },
      product () {
        return this.routePath.includes('foretag')
          ? 'business'
          : 'consumer'
      },
      beigeBackground () {
        return ['/', '/foretag', '/salj-el', '/butik', '/butikk', '/butik/foretag'].includes(this.routePath)
      },
      hasSiteInformation () {
        return this.getFeatures.siteinformation?.enabled
      }
    },
    mounted () {
      this.setScreenWidth()
      window.addEventListener('resize', this.setScreenWidth)
      if (!this.routePath.startsWith('/forsakring')) {
        addConnectelChat(this.COUNTRY)
      }
    },
    beforeDestroy () {
      window.removeEventListener('resize', this.setScreenWidth)
    },
    methods: {
      ...mapMutations('utils', ['setScreenWidth']),
      getProp,
      loaded () {
        this.pageView()
      },
      pageView () {
        const ga = getProp(this.getRoute, 'meta', 'ga')
        if (!ga) return

        const { pageName, pageType = 'content', level = 'level-2' } = ga

        this.ZGA.event.page.pageView({
          pageName,
          cg1: level,
          cg2: this.GA.PRODUCT_FAMILY,
          cg4: this.GA.PRODUCT[this.product],
          cg5: pageType
        })
      }
    }
  }
</script>

<style lang="scss">
  @import "~@ocp-zmarta/zmarta-components/src/scss/themes/zmarta/_global";

  .page {
    &__bg {
      height: rhythm("double");
      width: 100%;
      overflow: hidden;

      @include mq("large") {
        height: rem(56px);
      }

      svg {
        width: 100%;
        height: auto;
      }
    }

    &__siteinformation {
      height: rem(calc(56px + 48px));

      @media screen and (max-width: 470px) {
        height: rem(calc(56px + 72px));
      }

      @include mq("large") {
        height: rem(calc(56px + 48px));
      }
    }
  }

  .page-nav {
    min-height: 100vh;
  }

  .background-color--beige {
    background-color: color(beige);
  }
</style>
